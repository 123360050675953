<template>
  <div class="container">
    <el-tree
      :data="treeData"
      :props="defaultProps"
      @node-click="handleNodeClick"
      lazy
      :load="lazyLoad"
    >
      <span class="tree_item" slot-scope="{ node }">
        <span>{{ node.label }}</span>
      </span>
    </el-tree>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "TreeUserCategory",
  props: {
    categorycode: String,
  },
  data() {
    return {
      loading: false,
      loginData: null,
      treeData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  created() {
    this.initList();
  },
  methods: {
    initList() {
      this.loading = true;
      api
        .getTreeMUserList({
          // categorycode: "0000000",
          categorycode: this.categorycode,
        })
        .then((res) => {
          if (res.data.success === "1") {
            // this.treeData = res.data.data;
            let data = res.data.data;
            data.forEach((e) => {
              this.treeData.push({
                item: e,
                label: e.userfullname,
                children: [],
              });
            });
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    handleNodeClick(data) {
      console.log(data);
    },
    lazyLoad(node, resolve) {
      console.log(node);
      if (node.level === 0) {
        return resolve([]);
      }
      let item = node.data.item;
      api
        .getTreeMUserList({
          categorycode: item.categorycode,
        })
        .then((res) => {
          if (res.data.success === "1") {
            let data = res.data.data ? res.data.data : [];
            let children = [];
            data.forEach((e) => {
              children.push({
                item: e,
                label: e.userfullname,
                children: [],
              });
            });
            resolve(children);
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
  },
};
</script>

<style>
.container {
}
.el-tree-node__content {
  height: 50px;
}
</style>