<template>
  <div class="container">
    <div class="toolbar">
      <div style="flex: 1">
        <span style="font-size: 13px;color: #666">搜索姓名：</span>
        <el-input placeholder="搜索姓名" v-model="userfullname" style="width: 280px">
          <el-button slot="append" icon="el-icon-search" @click="searchList"></el-button>
        </el-input>
      </div>
    </div>
    <el-table
        v-loading="loading"
        :data="tableData"
        border
        size="small"
        style="width: 100%">
      <el-table-column
          type="index"
          label="#"
          width="50">
      </el-table-column>
      <el-table-column
          prop="categoryname"
          label="账户等级"
          min-width="150">
      </el-table-column>
      <el-table-column
          prop="username"
          label="用户名"
          width="220">
      </el-table-column>
      <el-table-column
          prop="userfullname"
          label="姓名"
          width="220">
      </el-table-column>
      <el-table-column
          label="代理区域"
          min-width="220">
        <template slot-scope="scope">
          <span>{{scope.row.provincename + ' ' + scope.row.cityname + ' ' + scope.row.districtname}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="createtime"
          label="注册时间"
          width="220">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button @click="bindParent(scope.row)" type="primary" plain size="mini"
                     :disabled="scope.row.categorycode == current.categorycode">绑定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :page-sizes="[10 ,15, 30]"
          :page-size="pageSize"
          layout="total,sizes, prev, pager, next, jumper"
          :total="totalData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: "AddCategory",
  props:{
    current: Object,
    closeAndRefresh: Function,
  },
  data(){
    return{
      tableData:[],
      pageIndex: 1,
      pageSize: 15,
      totalData: 0,
      loading: false,
      userfullname: '',
    }
  },
  created() {
    this.initList();
  },
  methods:{
    initList(){
      this.loading = true;
      api.getBindableMUserList({
        userlevel: this.current.categorylevel,
        page: this.pageIndex,
        pageSize: this.pageSize,
        userfullname: this.userfullname,
      }).then(res =>{
        if(res.data.success === '1'){
          this.totalData = res.data.data.totalRecords;
          this.tableData = res.data.data.list;
        }else {
          this.$message({
            showClose: true,
            message: res.data.errorMsg,
            type: 'warning'
          });
        }
        this.loading = false;
      }).catch(() =>{
        this.loading = false;
        this.$message({
          showClose: true,
          message: '请检查网络连接',
          type: 'warning'
        });
      });
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.initList();
    },
    handleSizeChange(val){
      this.pageSize = val;
      this.initList();
    },
    onTabChange(){
      this.initList();
    },
    bindParent(val){
      this.closeAndRefresh(this.current.categorycode,val.categorycode);
    },
    searchList(){
      this.pageIndex = 1;
      this.initList();
    },
  }
}
</script>

<style scoped>
.container{
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.footer{
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
.toolbar{
  margin-left: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}
</style>