<template>
  <div class="container">
    <div class="toolbar">
      <span style="font-size: 14px; color: #606266">搜索姓名：</span>
      <el-input
        placeholder="搜索姓名"
        v-model="userfullname"
        style="width: 280px"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="searchList"
        ></el-button>
      </el-input>
      <el-button
        type="primary"
        style="margin-left: 100px"
        v-on:click="viewAllCategoryDetails"
        >查看系统全部关系</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="medium"
        v-on:click="addCategory1"
        v-if="!rolecode"
        >添加代理账户</el-button
      >
    </div>

    <el-table
      v-loading="loading"
      :data="tableData"
      border
      size="small"
      style="width: 100%"
    >
      <el-table-column type="index" label="#" width="50"> </el-table-column>
      <el-table-column prop="categoryname" label="账户等级" min-width="100">
      </el-table-column>
      <el-table-column prop="username" label="用户名" width="160">
      </el-table-column>
      <el-table-column prop="userfullname" label="姓名" width="160">
      </el-table-column>
      <el-table-column label="代理区域" width="160">
        <template slot-scope="scope">
          <span>{{
            scope.row.provincename +
            " " +
            scope.row.cityname +
            " " +
            scope.row.districtname
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="上级代理等级" width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.parent.categoryname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="上级代理信息" min-width="220">
        <template slot-scope="scope">
          <span>{{
            scope.row.parent.userfullname + " - " + scope.row.parent.username
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="注册时间" width="160">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="400">
        <template slot-scope="scope">
          <el-button
            @click="viewDetails(scope.row)"
            type="primary"
            plain
            size="mini"
            >查看</el-button
          >
          <el-button
            @click="addCategory(scope.row)"
            v-if="!rolecode"
            type="success"
            plain
            size="mini"
            >绑定上级</el-button
          >
          <el-button
            @click="viewCategoryDetails(scope.row)"
            type="warning"
            v-if="!rolecode"
            plain
            size="mini"
            >查看下级代理</el-button
          >
          <el-button
            @click="clearCategory(scope.row)"
            type="danger"
            v-if="!rolecode"
            plain
            size="mini"
            >清除代理关系</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[10, 15, 30]"
        :page-size="pageSize"
        layout="total,sizes, prev, pager, next, jumper"
        :total="totalData"
      >
      </el-pagination>
    </div>

    <el-dialog
      append-to-body
      title="账户详情"
      :visible.sync="viewDetailDialog"
      v-if="viewDetailDialog"
      width="700px"
    >
      <ViewUserDetails :userData="viewDetailObj"></ViewUserDetails>
    </el-dialog>

    <el-dialog
      append-to-body
      title="绑定上级"
      :visible.sync="addCategoryDialog"
      v-if="addCategoryDialog"
      width="960px"
    >
      <AddCategory
        :current="viewDetailObj"
        :closeAndRefresh="closeAndRefresh"
      ></AddCategory>
    </el-dialog>

    <el-dialog
      append-to-body
      title="代理关系树形图"
      :visible.sync="treeDialog"
      v-if="treeDialog"
      width="960px"
    >
      <TreeUserCategory
        :categorycode="viewDetailObj.categorycode"
        :userlevel="viewDetailObj.categorylevel"
      ></TreeUserCategory>
    </el-dialog>
    <el-dialog
      append-to-body
      title="新增账户"
      :visible.sync="addDetailDialog"
      v-if="addDetailDialog"
      width="700px"
    >
      <AddEditUserDetails
        :closeAndRefresh="closeAndRefreshAdd"
      ></AddEditUserDetails>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api";
import router from "../../router";
import ViewUserDetails from "../../components/ViewUserDetails";
import AddCategory from "@/components/AddCategory";
import TreeUserCategory from "@/components/TreeUserCategory";
import AddEditUserDetails from "../../components/AddEditUserDetails";
/**
 * userlevel用户等级	String	1 = 省级代理   2=市级代理   3=代理人   4=医院
 */
export default {
  components: {
    TreeUserCategory,
    AddCategory,
    ViewUserDetails,
    AddEditUserDetails,
  },
  data() {
    return {
      addDetailDialog: false,
      pageIndex: 1,
      pageSize: 15,
      totalData: 0,
      tableData: [],
      loading: false,
      role: "省级代理",
      viewDetailObj: {},
      viewDetailDialog: false,
      addCategoryDialog: false,
      userfullname: "",
      treeDialog: false,
      loginData: null,
      rolecode: false,
    };
  },
  created() {
    this.loginData = JSON.parse(localStorage.getItem("logindata"));
    let showAll = this.loginData.urls.find(
      (e) => e.url === "province_look_role"
    ); //是否只展示查看
    if (showAll) this.rolecode = true; //如果code为true是否只展示查看
    if (!this.loginData) {
      this.$message({
        showClose: true,
        message: "用户信息失效，请重新登录",
        type: "warning",
      });
      router.push("/Login");
      return;
    }
    this.initList();
  },
  methods: {
    initList() {
      this.loading = true;
      api
        .getMUserCategoryList({
          page: this.pageIndex,
          pageSize: this.pageSize,
          categorylevel: this.loginData.userlevel,
          categorycode: this.loginData.categorycode,
          userfullname: this.userfullname,
        })
        .then((res) => {
          if (res.data.success === "1") {
            this.totalData = res.data.data.totalRecords;
            this.tableData = res.data.data.list;
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    closeAndRefreshAdd() {
      this.addDetailDialog = false;
      this.initList();
    },
    viewDetails(row) {
      this.viewDetailObj = row;
      this.viewDetailDialog = true;
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.initList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.initList();
    },
    onTabChange() {
      this.initList();
    },
    viewCategoryDetails(row) {
      this.viewDetailObj = row;
      this.treeDialog = true;
    },
    viewAllCategoryDetails() {
      this.viewDetailObj = {
        categorycode: this.loginData.categorycode,
      };
      this.treeDialog = true;
    },
    addCategory(row) {
      this.viewDetailObj = row;
      this.addCategoryDialog = true;
    },
    closeAndRefresh(currentcode, targetcode) {
      api
        .updateMUserCategory({
          currentcode: currentcode,
          targetcode: targetcode,
        })
        .then((res) => {
          if (res.data.success === "1") {
            this.addCategoryDialog = false;
            this.$message({
              showClose: true,
              message: "绑定成功",
              type: "success",
            });
            this.initList();
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    addCategory1() {
      this.addDetailDialog = true;
    },
    searchList() {
      this.pageIndex = 1;
      this.initList();
    },
    clearCategory(val) {
      this.$confirm("确定要清除代理关系吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        api
          .clearMUserCategory({
            categorycode: val.categorycode,
          })
          .then((res) => {
            if (res.data.success === "1") {
              this.$message({
                showClose: true,
                message: "清除成功",
                type: "success",
              });
              this.initList();
            } else {
              this.$message({
                showClose: true,
                message: res.data.errorMsg,
                type: "warning",
              });
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$message({
              showClose: true,
              message: "请检查网络连接",
              type: "warning",
            });
          });
      });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.footer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
.toolbar {
  margin-left: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>